/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // Smooth Scrolling Script by @chriscoyier
        $('a[href*="#"]:not([href="#"])').click(function () {
          if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
            var target = $(this.hash);
            var viewportWidth = $(window).width();
            var content = 'html,body';
            if(viewportWidth < 768){
                content = '.content-wrap';
            }
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
              $(content).animate({
                scrollTop: target.offset().top
              }, 1000);
              return false;
            }
          }
        });

        // Close old browser warning in IE lt 10 on click
        $('.close-oldie').click(function(){
            $( ".oldie-warning" ).fadeOut();
        });

        // Stop scrolling the page when user inteacts
        $("html, body").bind("scroll mousedown DOMMouseScroll mousewheel keyup", function(){
          $('html, body').stop();
        });


        $.fn.toggleElementsOnScroll = function() {
          var viewportHeight = $(document).height();
          var y = $(this).scrollTop();
          if (y > viewportHeight/2) {
            $('.to-top').removeClass('hidden');
            $('.floaty-wrap').removeClass('hidden');
          } else {
            $('.to-top').addClass('hidden');
            $('.floaty-wrap').addClass('hidden');
          }
        };

        // MediaQuery triggered functions
        var mqDesktop = Modernizr.mq('(min-width: 768px)');
        if (mqDesktop) {

          // Scroll-Top-Btn / Floaty-Bar fading function
          // Different scroll targets on mobile / desktop
          $(document).scroll(function() {
            $(this).toggleElementsOnScroll();
          });

          // Define Vars for affix calcs
          var nav = $('#desktop-nav');
          var navHeight = $('#menu-main-navigation').height();
          var offsetAffix = $('#menu-main-navigation').offset();

          // Makes the nav sticky
          $(nav).affix({
              offset: {
                top: (offsetAffix.top - 12) // You may have to add some extra px for borders etc.
              }
          });

          // Add padding to body to prevent lil' page-jump when nav is stickfied
          $(nav).on('affix.bs.affix', function () {
             //$('body').css('padding-top', navHeight + 'px');
             $('body').css('padding-top', '85px');
          });

          // Remove the x-tra padding after nav gets static again
          $(nav).on('affixed-top.bs.affix', function () {
             $('body').css('padding-top', '0');
          });

          // Recalculate offset for sticky element and position for mega dropdown centering on resize
          $( window ).resize(function() {
            var offsetAffix = $(nav).offset();
          });
        }
        else {
          // Scroll-Top-Btn / Floaty-Bar fading function
          // Different scroll targets on mobile / desktop
          $('.content-wrap').scroll(function() {
            $(this).toggleElementsOnScroll();
          });
        }
        if($('body').hasClass('touchevents')) {
          $( '.touchevents #desktop-nav li:has(ul)' ).doubleTapToGo();
        }

        if($('#cookie-notice').is(':visible')) {
            $('.copyright-line').css('padding-bottom', '90px');

            $('#cn-accept-cookie').click(function(){
                $('.copyright-line').animate({'padding-bottom': '0'}, 500);
            });
        }
      },
    finalize: function() {
      // JavaScript to be fired on all pages, after page specific JS is fired
    }
  },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
